<template>
  <Layout>
    <div class="base-width">
      <div class="main-pay text-center s16 c-card">
        <!-- 订单详情按钮 -->
        <div class="flex-right">
          <BaseButton style-type="light" icon="right" tag="router-link" to="/order-detail">查看订单详情</BaseButton>
        </div>
        <div class="color-4 t1 lh-30">
          账期采购支付剩余时间：10时45分18 秒
        </div>
        <div class="font-medium t2 price flex-center">
          <span class="s32 color-2">¥500.00</span>
        </div>
        <div class="line"></div>
        <div class="color-4 t4 lh-30">
          订单提交成功，请尽快支付！订单号：41551651651 <br>  
          请在10时45分18 秒内完成，否则订单将会自动取消
        </div>
        <!-- 支付方式 -->
        <OrderPayWay v-model="payWay"></OrderPayWay>
        <!-- 按钮 -->
        <div class="s16 flex-center btns color-f">
          <div class="btn btn-1" @click="handlePay">立即支付</div>
          <div class="btn btn-2 bg-9">取消</div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      payWay: 1,
    };
  },
  methods: {
    handlePay() {
      if (this.payWay == 1 || this.payWay == 2) {
        this.$router.push('/pay-code')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-pay{
  padding: .3rem; padding-bottom: .8rem;
  margin-top: .37rem; margin-bottom: .6rem;
  .t1{
    margin-top: .02rem;
  }
  .price{
    margin-top: .12rem;
  }
  .t3{
    margin-top: .14rem;
  }
  .lh-30{
    line-height: calc(30 / 16 * 1em);
  }
  .line{
    height: 1px;
	  background-color: #e6f2f8;
    margin: .23rem 0 .2rem;
  }
  .btns{
    margin-top: .36rem;
    .btn{
      width: 1.3rem;
      line-height: 0.5rem;
      border-radius: 0.04rem;
      margin: 0 .1rem;
      text-align: center;
      cursor: pointer;
    }
    .btn-1{
      background: #e01624;
    }
  }
}
.dialog-text{
  margin: 0.38rem 0 .23rem;
  line-height: calc(42 / 26 * 1em);
}
</style>